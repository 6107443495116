const errorHandler = (response:any):any=>{
    return new Promise((resolve, reject)=>{
        try{
            response.json().then((data:any)=>{
                if (data && data.message){
                    resolve(typeof data.message == "object" ? data.message.join(", ") : data.message);
                }
            })
        }catch{
            resolve("Váratlan hiba történt");
        }
    });
}

export default errorHandler;