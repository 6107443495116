import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import postDataJson from "../connection/postDataJson";
import Error from "../notification/error.component";
import BuyButton from "./buy-button.component";
import Legend from "./legend.component";
import TicketPageItems from "./page-items";
import Seats from "./seats.component";
import TicketSkeleton from "./ticket-skeleton.component";
import Tickets from "./tickets.component";
import Cookies from 'universal-cookie';
import Schema from "./schema";
import TypeOfTicketType from "./types/TypeOfTicketType";
import '../../css/buy-ticket-page.css';
import Request from "../../network/request";
import errorHandler from "../../errorHandler/ErrorHander";
import { useNavigate } from "react-router-dom";

type typeOfTicket = {
  id: string;
  numberOfTicket: number;
  places: Array<string>;
  price: number;
  name: string;
  ticketId: string;
  pendingPlaces: Array<string>;
  numberOfFreeTickets: number;
  boughtPlaces: Array<string>;
  types : Array<TypeOfTicketType>;
  selected? : number
};

type typeOfAmountTicketType ={
  id : string,
  amount : number,
  price : number,
  seats : Array<string>
}

type typeOfAmountTicket = {
  id: string;
  numberOfTicket: number;
  places: Array<string>;
  price: number;
  name: string;
  ticketId: string;
  pendingPlaces: Array<string>;
  numberOfFreeTickets: number;
  boughtPlaces: Array<string>;
  types? : Array<typeOfAmountTicketType>,
  amount : number,
  selected: number
};

type typeOfPageParams = {
  title: string;
  background: string;
  description: string;
  date: string;
  id: string;
  placeDatas: typeOfPlaces;
  media: typeOfMedia;
  location: string;
  position: typeOfCenter;
  address: string;
  ticketId: string;
  venueId: string;
  performer : string,
  isGroup : boolean,
  endDate : string,
  ticketGroups : Array<typeOfAmountTicket>,
  orderedTickets : Array<string>
};
type typeOfAmount = {
  id: string;
  amount: number;
};

type typeOfSeat = {
  group: string;
  id: string;
  name: string;
  posX: number;
  posY: number;
  title: string;
};
type typeOfMedia = {
  apple_music?: string;
  spotify?: string;
  youtube?: string;
  facebook?: string;
  instagram?: string;
};

type typeOfPlaces = {
  background: { isImage: boolean; name: "string" };
  colorOfBackground: string;
  colorOfSeat: string;
  seats: Array<typeOfSeat>;
  sizeOfArea: { width: number; height: number };
  sizeOfSeat: number;
  stage: number;
};
type typeOfCenter = {
  lat: number;
  lng: number;
};

const sumAmountOfAllTypes = (types:Array<typeOfAmountTicketType>)=>{
  let sum = 0;
  types.forEach(type=>{
    sum += type.amount;
  });
  return sum
};

type typeOfVenue = {
  id : string;
  width : number
  height : number;
  seats : Array<typeOfSeat>
}

type cartItemType = {
  ticketId : string;
  ticketGroupId : string;
}

const Page = ({
  title,
  background,
  description,
  date,
  id,
  media,
  position,
  location,
  address,
  ticketId,
  venueId,
  performer,
  isGroup,
  endDate,
  ticketGroups,
  orderedTickets
}: typeOfPageParams) => {

  const [cookies, setCookiesFunction] = useState(new Cookies(null, { path: window.location.pathname }));
  const [ticketsAmount, setTicketsAmount]: [Array<any>, Function] = useState(
    ticketGroups
  );
  const [cart, setCart] = useState<Array<cartItemType>>([]);


  const [loadingTickets, setLoadingTickets] = useState<boolean>(false);
  const genereateTicketAmoutWithOutCookie = (tickets:Array<typeOfTicket>)=>{
    let newList: Array<any> = [];
    for (let i = 0; i < tickets.length; i++) {
      tickets[i].types = tickets[i].types.map((type)=> {return {...type, amount : 0}});
      tickets[i].selected = 0;
      newList.push({ ...tickets[i]});
    }
   setTicketsAmount(newList);
  }


  const genereateTicketAmout = (
    tickets: Array<typeOfTicket>,
  ) => {
    let objectId = cookies.get('orderId');
    let newList: Array<any> = [];
    if (objectId){
    }else{
      genereateTicketAmoutWithOutCookie(tickets);
    }
  };

  /*
  if (objectId){
      
    }*/
  //const [tickets, setTickets]:[typeOfTicket, Function] = useState();
  const [placeDatas, setPlaceDatas]: [any, Function] = useState([]);

  const [selectedTickets, setSelectedTickets]: [Array<string>, Function] =
    useState([]);
  const [errorNat, setErrorNat]: [string, Function] = useState("");
  const [selectNotification, setSelectNotification] = useState(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    let objectId = cookies.get('orderId');
    setLoadingTickets(true);
    fetch(`/api/v1/events/venue/${venueId}?event=${ticketId}`).then(
      async (response) => {
        let venue = await response.json();
        if (!venue.error && venue) {
          setPlaceDatas(venue);
        }
      },
    );
  }, []);



  const incrementAmountOfTickets = (id: string, typeId:string) => {
    if (ticketsAmount.find(ticket=>{return ticket.id === id})?.amount >  cart.filter((item)=>item.ticketId === id).length) setCart(prev=>[...prev, {ticketId : typeId, ticketGroupId : id}]);
  };

  const getIncludedSeats = (group: any) => {
    let included:any = [];
    console.log("group", group);
    selectedTickets.forEach((seat) => {
      if (group.seats.includes(seat)) {
        included.push(seat);
      }
    });
    return included;
  }

  function deleteOneItemById(array:any, targetId:string) {
    let s = getIncludedSeats(ticketsAmount.find((item:any)=>{return item.id === targetId}))
    console.log(s);
    const indexToDelete = array.findIndex((item:any) => s.includes(item));
    if (indexToDelete === -1) {
      return array;
    }
    
    return [
      ...array.slice(0, indexToDelete),
      ...array.slice(indexToDelete + 1)
    ];
}


  const decrementAmountOfTickets = (id: string, typeId: string) => {
    if (cart.filter((item)=>{return item.ticketId === typeId}).length){
    let lCart = [...cart];
    let newCart: Array<any> = [];
    let counter = 0;
    for (let i = 0; i < lCart.length; i++) {
      if (lCart[i].ticketId === typeId && lCart[i].ticketGroupId === id && counter === 0) {
        counter++;
        continue;
      }
      newCart.push(lCart[i]);
    }
    if (cart.filter((item:any)=>{return (item.ticketGroupId == id)}).length <= getIncludedSeats(ticketsAmount.find((item:any)=>{return item.id === id})).length) setSelectedTickets((prev:any)=>deleteOneItemById(prev, id));
    setCart(newCart);
    }
  };


  const selectSeat = (id: string) => {
    if (!orderedTickets?.includes(id) && !selectedTickets.includes(id) && cart.filter((item:any)=>{return (ticketsAmount.find((t:any)=>t.seats.includes(id)).id == item.ticketGroupId)}).length > getIncludedSeats(ticketsAmount.find((item:any)=>item.seats.includes(id))).length) {setSelectedTickets((prev:any)=>[...prev, id]);

    }
    else setSelectedTickets((prev:any)=>prev.filter((item:string)=>{return item !== id}));
  };

  const getTotalAmountOfTickets = () => {
      return cart.length;
  };

  const buy_Ticket = () => {
    let sendData:any = {};
        let error = [];
        let control = false;
        let s = [...selectedTickets];
        if (cart.length) {
            sendData["tickets"] = [];
            cart.forEach((item:any)=>{
                let filteredSeats = ticketsAmount.find((group:any) => group.id === item.ticketGroupId)?.seats;
                let seatId = s.find((t:any)=>filteredSeats.includes(t));
                if (seatId) s = s.filter((i:string)=>i !== seatId);
                else if (filteredSeats.length) {error.push("Kérem válassza ki a helyeket!"); return;}
                sendData["tickets"].push({ticketGroup : item.ticketGroupId, ticketId : item.ticketId, seat : seatId || ""});
            });
          //sendData["tickets"] = cart.map((item)=>{return {ticketGroup : item.ticketGroupId, ticketId : item.ticketId, seat : takeOutTicket(item.ticketGroupId)}});
          const url = new URL(window.location.href);
          sendData["eventId"] = id;
          control = true;
        }else{
          control = false;
        }
        if (!control) {
          error.push("Kérem adja meg a vásárolni kívánt mennyiséget!");
        }
        if (!error.length) {
          Request("POST", `/api/v1/order`, sendData).then(
            async (response:any) => {
                response.text().then((t:any)=>{
                  navigate(`/jegyvasarlas/${t}`);
                });
            }
          ).catch(async (e)=>{
            setErrorNat(await errorHandler(e));
          });
          return;
        }
        if (!error.length) {
          error.push("Hiba történet az oldal betöltése közben");
        }
        setErrorNat(error.join(","));
  };

  const toOtherInformations = () => {
    const element: any = document?.getElementById("event-page-description");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const toSelectTickets = () => {
    //
    const element: any = document?.getElementById("seat-map-canvas-holder");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const [isShaking, setShaking] = useState(false);

  const handleShakeButtonClick = () => {
    setShaking(true);

    // Reset shaking state after a short delay
    setTimeout(() => {
      setShaking(false);
    }, 500);
  };

  console.log(ticketsAmount);

  return (
    <div className="event-page-div">
      { ticketsAmount ? 
        <Schema title={title} description={description} startDate={date} endDate = {endDate} ticketPrice={ticketsAmount.length ? ticketsAmount[0].price : 0} image={`${window.location.origin}${background}`} performer={performer} isGroup = {isGroup} placeName={location} />
      : <></>}
      <Error
        message={errorNat}
        open={errorNat != ""}
        setOpen={() => setErrorNat("")}
      />
      <TicketPageItems
        title={title}
        image={background}
        description={description}
        date={date}
        media={media}
        position={position}
        location={location}
        address={address}
      />
      {ticketsAmount && ticketsAmount.length ? (
        <Tickets
          cart = {cart}
          tickets={ticketsAmount}
          incrementFunction={incrementAmountOfTickets}
          decrementFunction={decrementAmountOfTickets}
        />
      ) : (
        loadingTickets ? <TicketSkeleton /> : <></>
      )}
      {placeDatas && placeDatas.seats && placeDatas.seats.length ? (
        <Legend />
      ) : (
        <></>
      )}
      {placeDatas &&
      placeDatas.seats &&
      placeDatas.seats.length &&
      ticketsAmount &&
      ticketsAmount.length ? (
        <Seats
          cart = {cart}
          orderedTickets = {orderedTickets}
          places={placeDatas}
          tickets={ticketsAmount}
          seleted={selectedTickets}
          onClickFunction={selectSeat}
        />
      ) : (
        <></>
      )}
      <div className="alert-notification">
        <Collapse in={selectNotification}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSelectNotification(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Válassza ki a helyeket a jegy tükrön!
          </Alert>
        </Collapse>
      </div>
      <BuyButton
        isLoading={isLoading}
        error={!!errorNat}
        shakeButton={isShaking}
        selectTicketsFunction={toSelectTickets}
        isSeats={placeDatas && placeDatas.seats && placeDatas.seats.length}
        otherInformationFunction={toOtherInformations}
        amountOfTickets={ticketsAmount ? getTotalAmountOfTickets() : 0}
        onClickFunction={buy_Ticket}
      />
    </div>
  );
};
export default Page;
