import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ContactlessIcon from "@mui/icons-material/Contactless";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import "../../css/buy-ticket-main.css";
import Loader from "../loader/loader.component";
import Error from "../notification/error.component";
import BillingInformations from "./billing-infomations.component";
import Coupon from "./buy-ticket-coupon.component";
import Details from "./buy-ticket-details.component";
import DatasOfCustomer from "./datas-of-customer.component";
import BuyingStepper from "./stepper.component";
import postDataJson from "../connection/postDataJson";
import errorHandler from "../../errorHandler/ErrorHander";
import Request from "../../network/request";

type typeOfBillAddress = {
  firstname: string;
  lastname: string;
  postalCode: number;
  city: string;
  taxNumber: string;
  phone: string;
  mail: string;
};

type typeOfTicketDatas = {
  eventId: string;
  tickets: Array<typeOfTickets>;
  fullAmount: number;
  fullPrice: number;
  eventName: string;
  dateOfEvent: number;
  customerDatas: typeOfBillAddress;
};

type typeOfTickets = {
  name: string;
  price: number;
  seat: string;
  id : string;
};

type typeOfCoupon = {
  name: string;
  money: boolean;
  amount: number;
};
const BuyTicketMainPage = () => {
  const [sameInvoiceData, setSameInvoiceData]: [boolean, Function] =
    useState(true);
  const [referalCode, setReferalCode]: [string, Function] = useState("");
  const [usedCoupon, setUsedCoupon]: [typeOfCoupon, Function] = useState({
    name: "",
    money: false,
    amount: 0,
  });
  const [error, setError]: [string, Function] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [fetching, setfetching] = useState(false);
  const [step, setStep] = useState(1);
  const [link, setLink] = useState("");
  const [isCompany, setIsCompany] = useState(false);
  const [checkCoupon, setCheckCoupon] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<any>({});
  const [ticketDatas, setTicketDatas]: [typeOfTicketDatas, Function] = useState(
    {
      eventId: "",
      tickets: [],
      fullAmount: 0,
      fullPrice: 0,
      eventName: "",
      dateOfEvent: 0,
      customerDatas: {
        firstname: "",
        lastname: "",
        postalCode: 0,
        city: "",
        taxNumber: "",
        phone: "",
        mail: "",
      },
    },
  );

  const sendDatas = () => {
    if (terms){
    if (
      firstname &&
      String(postCode).length === 4 &&
      city &&
      address &&
      phone &&
      mail && (!isCompany || taxNumber)
    ) {
      setfetching(true);
      let data = {
        userName : firstname,
        userEmail : mail,
        userPhone : phone,
        userAddress : address,
        userCity : city,
        userZip : Number(postCode),
        userCompany : isCompany,
        taxNumber : taxNumber,
        userAddress2 : address2
      };
      let token = window.location.pathname.split("/")[2];
      Request("POST", `/api/v1/order/details/${token}`, data).then((response:any)=>{
          response.json().then((data:any)=>{
            if (data.link) setLink(data.link);
          })
            setStep(2);
            getDatas();
          setfetching(false);
      }).catch(async (err)=>{
        setError(await errorHandler(err));
        setfetching(false);
      })
    }
    else{
      setError("Kérem minden kötelező mezőt töltsön ki");
    } 
  }else{
    setError("A továbblépéshez el kell fogadnia a felhasználási feltételeket");
  }
  };

  const pay = () => {
    if (link) {
      window.location.href = link;
    } else{
      setError("Hiba történt a fizetés során");
    }
  };

  const getDatas = () => {
    let token = window.location.pathname.split("/")[2];
    Request("GET", `/api/v1/order/details/${token}`).then((response:any) => {
      response.json().then((data:any) => {
      if (data) {
        setOrderData(data);
        setIsCompany(data.userCompany);
        setFirstname(data.userName);
        setPostCode(data.userZip);
        setCity(data.userCity);
        setAddress(data.userAddress);
        if (data.userAddress2)
          setAddress2(data.userAddress2);
        if (data.taxNumber)
          setTaxNumber(data.taxNumber);
        setPhone(data.userPhone);
        setMail(data.userEmail);
        }});
    }).catch(async (error:any)=>{
      setError(await errorHandler(error));
    });
  };

  useEffect(() => {
    getDatas();
  }, []);

  const priceSum = ()=>{
    let sum = 0;
      for (let i = 0; i < orderData.tickets?.length; i++){
        sum += orderData.tickets[i].price
      }
    return sum;
  }

  return (
    <div>
      <Error
        message={error}
        setOpen={() => {
          setError("");
        }}
        open={error !== ""}
      />
      <div style={{ margin: 10 }}>
        <BuyingStepper active={step} />
      </div>
      {step === 1 ? (
        <div>
          <h1 className = "ticket-buying-main-title">Adatok megadása</h1>
          <div className="first-step-details-holder">
            <DatasOfCustomer
              isTermsAccespted={terms}
              acceptTermsFunction={setTerms}
              isCompany={isCompany}
              setIsCompany={setIsCompany}
              setFirstName={setFirstname}
              setPostalCode={setPostCode}
              setCity={setCity}
              setAddress={setAddress}
              setAddress2={setAddress2}
              setMail={setMail}
              setPhone={setPhone}
              setTaxNumber={setTaxNumber}
              city={city}
              firstName={firstname}
              zip={postCode}
              tax={taxNumber}
              mail={mail}
              phone={phone}
              address={address}
              address2={address2}
              setInvoiceName={() => {}}
              sameInvoiceData={sameInvoiceData}
              setSameInvoiceData={setSameInvoiceData}
            />
            <div className="details-1st-step">
              {orderData ? (
                <Details
                  tickets={orderData.tickets}
                  fullPrice={priceSum()}
                  nameOfEvent={orderData.event}
                  coupon={usedCoupon}
                />
              ) : (
                <Details />
              )}

              <div className="button-grid">
                <LoadingButton
                  size="small"
                  onClick={sendDatas}
                  endIcon={<ArrowForwardIcon />}
                  loading={fetching}
                  loadingPosition="end"
                  variant="contained"
                  className="payingButton"
                  style={{ background: "white", color: "#595959" }}
                >
                  Tovább a fizetéshez
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {step === 2 ? (
        <div>
          <h1>Adatok ellenőrzése</h1>
          <h2>Jegy adatai</h2>
          <div className="details-holder">
            <div className="details">
              {orderData ? (
                <Details
                  tickets={orderData.tickets}
                  fullPrice={priceSum()}
                  nameOfEvent={orderData.event}
                  coupon={usedCoupon}
                />
              ) : (
                <Loader />
              )}
            </div>
            <div className="details">
              <BillingInformations
                isCompany={isCompany}
                setIsCompany={setIsCompany}
                firstname={firstname}
                lastname={lastname}
                zip={postCode}
                city={city}
                address={address}
                address2={address2 === "null" ? undefined : address2}
                tax={taxNumber === "null" ? undefined : taxNumber}
                phone={phone}
                mail={mail}
              />
            </div>
          </div>
          <div className="paying-action-buttons">
            <LoadingButton
              size="small"
              onClick={() => {
                setStep(1);
              }}
              endIcon={<ArrowBackIcon />}
              loading={fetching}
              loadingPosition="end"
              variant="contained"
              className="payingButton"
              style={{ background: "white", color: "#595959" }}
            >
              Vissza
            </LoadingButton>
            <LoadingButton
              size="small"
              onClick={pay}
              endIcon={<ContactlessIcon style={{ color: "white" }} />}
              loading={fetching}
              loadingPosition="end"
              variant="contained"
              className="payingButton"
              style={{ background: "white", color: "#595959" }}
            >
              Fizetés
            </LoadingButton>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BuyTicketMainPage;
