import Seat from "./seat.component";
import "../../css/selectTickets.css"
import Stage from "./stage.component";
import { useEffect, useState } from "react";
import SeatVisualization from "../seat-visualization-engine/seats.component";
import typeOfStage from "../seat-visualization-engine/types/typeOfStage";

type typeOfSeat = {
    group : string,
    id : string,
    name : string,
    posX : number,
    posY : number,
    title : string,
    width : number,
    height : number,
    color : string
}

type typeOfPlaces = {
    background : {isImage : boolean, name : "string"},
    colorOfBackground : string,
    colorOfSeat : string,
    seats : Array<typeOfSeat>,
    sizeOfArea : {width : number, height : number},
    sizeOfSeat : number,
    stages : Array<typeOfStage>,
}

type typeOfAmountTicket = {
    id : string,
    numberOfTicket : number,
    seats : Array<string>,
    price : number,
    name : string,
    ticketId : string,
    amount : number,
    selected : number,
    pendingPlaces : Array<string>,
    boughtPlaces : Array<string>,
    sector: any
}

type typeOfSeatsParams = {
    places : typeOfPlaces,
    tickets : Array<typeOfAmountTicket>,
    seleted : Array<string>,
    onClickFunction : Function,
    disabled? : boolean,
    orderedTickets : Array<string>,
    cart : Array<{ticketGroupId : string, ticketId : string}>
}
const Seats = ({places, tickets, seleted,onClickFunction, disabled, orderedTickets, cart}:typeOfSeatsParams)=>{

    const [marginTop, setMarginTop] = useState(100);

    /*const getMinX = ()=>{
        let min = 0;
        /*places.seatsDatas.forEach((place:any) => {
                if (min === -1){ min = place.posX}
                else if (min > place.posX){
                    min = place.posX;
                }
        });
        return places.sizeOfArea.width > window.innerWidth ? min-100 : 0;
    }*/


    const getSizeOfArea = ()=>{
        let maxX = 0;
        let maxY = 0;
        let widthOfArea = window.innerWidth*0.8 > 500 ? 500 : window.innerWidth*0.8;
        if (places){
            places.seats.forEach((seat:any, index:number)=>{
                if (index === 0 || maxX < seat.posX+seat.width){maxX = seat.posX+seat.width};
                if (index === 0 || maxY < seat.posY){maxY = seat.posY};
            })
            if (places.stages){
                places.stages.forEach((stage, index)=>{
                    if (maxX === 0 || maxX < stage.posX+stage.width){maxX = stage.posX+stage.width};
                    if (maxY === 0 || maxY < stage.posY){maxY = stage.posY};
                });
            }
        }
        console.log(maxX, maxY);
        return {
            width : maxX,
            height : maxY+120
        }
    }



    return (<div className = "select-ticket-main-div">
            <SeatVisualization cart={cart} orderedTickets = {orderedTickets} stages={places.stages} disabled = {disabled} tickets = {tickets} seatPositions={places.seats} sizeOfArea={getSizeOfArea()} colorOfSeat={places.colorOfSeat} seatSize={places.sizeOfSeat} selectedSeats={seleted} selectFunction={onClickFunction} />
        </div>);
}

export default Seats;
