export default function Request(method:"GET"|"POST"|"PUT"|"DELETE" = "GET", url : string, body: any = ""){
        return new Promise((resolve, reject) => {
            return fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: body ? JSON.stringify(body) : undefined}
            ).then(response=>{
                if (response.status >= 200 && response.status < 300){
                    resolve(response);
                }else{
                    reject(response);
                }
            })
        });
}